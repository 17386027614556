<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getTeamMemberQuery",
  
  watch: {
    teamMembers() {
      this.$store.commit('graphqlData', { item: 'teamMembers', result: this.teamMembers });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_ATHLETE_URL,
      cachePolicy: 'network-only'
    });
    
    const getTeamMemberQuery = gql`
      query MyQuery($lang:Locale!) {
        teams(first: 6, locales: [$lang]) {
          slug
          name
          tags
        }
      }
    `;
    
    const { data: teamMembers } = useQuery({
      query: getTeamMemberQuery,
      variables
    });
    
    return { teamMembers };
  },
}
</script>