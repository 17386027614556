<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getTeamMemberImagesQuery",
  
  watch: {
    teamMemberImages() {
      this.$store.commit('graphqlData', { item: 'teamMemberImages', result: this.teamMemberImages });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_ATHLETE_URL,
    });
    
    const getTeamMemberImagesQuery = gql`
      query MyQuery {
        teams(first: 6) {
          images(first: 1) {
            url(
              transformation: {image: {resize: {height: 800}}, document: {output: {format: webp}}}
            )
          }
        }
      }
    `;
    
    const { data: teamMemberImages } = useQuery({
      query: getTeamMemberImagesQuery
    });
    
    return { teamMemberImages };
  },
}
</script>