<template>
  <div></div>
</template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'getAllNewsQuery',

  watch: {
    allNews() {
      this.$store.commit('graphqlData', { item: 'allNews', result: this.allNews });
    },
  },

  setup() {
    const store = useStore();

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
      cachePolicy: 'network-only',
    });

    const getAllNewsQuery = gql`
      query MyQuery($lang:Locale!) {
        news(orderBy: date_DESC, locales: [$lang]) {
          id
          preview{
            html
          }
          newsText{
            html
          }
          heading
        }
      }
    `;

    const { data: allNews } = useQuery({
      query: getAllNewsQuery,
      variables,
    });

    return { allNews };
  },
};
</script>
