<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getHomeImagesQuery",
  
  watch: {
    homeImages() {
      this.$store.commit('graphqlData', { item: 'homeImages', result: this.homeImages });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
    });
    
    const getHomeImagesQuery = gql `
      query MyQuery {
        home(where: {id: "ckupah4uor1u70b97gadvcngp"}) {
          backgroundImages(first: 10) {
            url(
              transformation: {document: {output: {format: webp}}, image: {resize: {width: 1500}}}
            )
          }
          fullscreenImage{
            url(
              transformation: {document: {output: {format: webp}}, image: {resize: {width: 1500}}}
            )
          }
          categories(first: 10) {
            item(first: 1) {
              images(first: 1) {
                url(
                  transformation: {document: {output: {format: webp}}, image: {resize: {width: 800}}}
                )
              }
            }
          }
        }
      }
    `;
    
    const { data: homeImages } = useQuery({
      query: getHomeImagesQuery,
      variables
    });
    
    return { homeImages };
  },
}
</script>