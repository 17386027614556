<template>
  <div class="font-stolzl max-w-screen overflow-hidden">

    <!-- INTRO-->
    <div id="intro" class="hidden transition duration-500 ease-in-out bg-white relative font-stolzl text-coolGray-900 w-screen h-screen md:flex flex-row justify-center items-center"
      :class="{'transition duration-500 ease-in-out bg-coolGray-900' : !navBarBackground}">

      <transition @enter="showInfo" @leave="hideInfo" :css="false">
        <div v-if="!swap" class="absolute w-auto h-auto" >
          <div v-if="randomUrl" id="re" class="relative select-none mix-blend-luminosity font-black p-20 bg-cover text-transparent text-5xl md:text-9xl bg-clip-text"
            :style="{ backgroundImage: 'url(' + randomUrl + ')' }">
            <div @mouseover="swap = true" class="select-none bg-clip-text">re:white</div>
            <div v-if="home?.home?.introSubtext" class="absolute text-coolGray-200 text-lg font-extralight tracking-widest">{{home.home.introSubtext}}</div>
          </div>
        </div>

        <div v-else class="absolute mix-blend-luminosity w-full h-full bg-cover bg-no-repeat text-9xl font-black flex flex-row justify-center items-center text-coolGray-900"
        :style="{ backgroundImage: 'url(' + randomUrl + ')' }" >
          <div @mouseleave="swap = false" class="select-none">re:white</div>
        </div>
      </transition>

      <div class="z-50 absolute animate-pulse inset-x-0 bottom-5 text-coolGray-50 font-light w-full flex flex-col justify-center items-center cursor-pointer" @click="scroll()">
        <div>scroll</div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        </div>

    </div>
    <!-- INTRO-->

    <!-- INTRO MOBILE -->
    <div class="md:hidden h-75 flex flex-col justify-center items-center text-coolGray-900 font-bold text-7xl mb-24">
      <div>re:white</div>
      <div v-if="home?.home?.introSubtext" class="text-base font-light">{{home.home.introSubtext}}</div>
    </div>
    <!-- INTRO MOBILE -->

    <!-- HEADING -->
    <div id="first" class="transition duration-500 ease-in-out w-full h-auto bg-white md:py-28" :class="{'transition duration-500 ease-in-out md:bg-coolGray-900' : !navBarBackground}">
      <div class="w-auto h-auto overflow-hidden px-4 md:px-8 py-4 md:py-10 ">
        <div class="w-full  opacity-0" :ref="setRef" data-anim="0">
          <div v-if="home?.home?.introText" class="w-full md:w-5/6 text-2xl md:text-5xl font-light text-coolGray-900 md:leading-snug">{{home.home.introText}}</div>
        </div>
      </div>
    </div>
    <!-- HEADING -->

    <!-- NEWS -->
    <div class="w-full h-auto px-4 md:px-8 opacity-0 md:mt-0 mt-12" :ref="setRef" data-anim="0">
      <div class="relative w-full h-auto bg-coolGray-100">
        <div  class="absolute top-0  mt-4 md:mt-8 pl-5 md:pl-10 w-full flex flex-row justify-start items-center">
          <div class="bg-coolGray-900 text-coolGray-50 mr-1 rounded-lg text-xs font-normal px-2 py-1 tracking-widest uppercase">{{text.news}}</div>
        </div>
        <div v-if="allNews?.news" class="flex flex-col md:flex-row overflow-hidden">
          <div v-for="(news, i) in allNews.news" :key=news.id class="p-4 md:p-10 pt-16 md:pt-16 w-full md:w-1/2 opacity-0" :ref="setRef" data-anim="0">
          <div v-if="i <= 1">
            <div class="text-lg text-coolGray-900 my-2">{{news?.heading}}</div>
            <div v-if="news?.preview?.html && newsToggle !== i" class="whitespace-pre-line text-sm font-light cursor-pointer" v-html="news.preview.html" @click="toggleNews(i)"></div>
            <div v-if="news?.newsText?.html && newsToggle === i" class="whitespace-pre-line text-sm font-light cursor-pointer" v-html="news.newsText.html" @click="toggleNews(i)"></div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <!-- NEWS -->

    <!-- SHOP BY CATEGORY -->
    <div v-if="home?.home?.categories && homeImages?.home?.categories" class="grid grid-cols-1 md:grid-cols-2 gap-8 px-4 md:px-8 mt-8 mb-10">
      <div v-for="(item,index) in home.home.categories" :key="item.slug" :ref="setRef" data-anim="0" class="h-66 w-full cursor-pointer opacity-0">

          <router-link :to="{ name: 'Categories', params: { categoryId: item.id, slug: item.slug } }" class="relative bg-coolGray-100 h-full w-full flex flex-row justify-center overflow-hidden items-center group">
            <div v-if="homeImages?.home?.categories[index]?.item[0]?.images[0]" class="transition duration-300 ease-in-out w-5/6 md:w-4/6 h-full transform-gpu md:group-hover:scale-105 bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + homeImages.home.categories[index].item[0].images[0].url + ')' }"></div>
            <div  class="absolute top-0  mt-4 md:mt-8 pl-5 md:pl-10 w-full flex flex-row justify-start items-center">
              <div class="bg-coolGray-900 text-coolGray-50 mr-1 rounded-lg text-xs font-normal px-2 py-1 tracking-widest">{{text.category}}</div>
            </div>
            <div class="w-full h-auto overflow-hidden absolute bottom-0 mb-5">
              <div :ref="setRef" data-anim="0" class="w-full flex flex-col md:flex-row justify-start md:justify-between items-start md:items-end px-4 md:px-8 opacity-0">
                <div>
                  <div class="font-medium text-lg text-coolGray-900">{{item.name}}</div>
                  <div class="text-sm text-coolGray-700 font-light">{{item.info}}</div>
                </div>
              </div>
            </div>
          </router-link>

      </div>
    </div>
    <!-- SHOP BY CATEGORY -->

    <!-- FULLSCREEN IMAGE -->
    <div v-if="home?.home && homeImages?.home?.fullscreenImage?.url" :ref="setRef" data-anim="0" class="relative opacity-0 w-screen h-screen bg-left md:bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + homeImages.home.fullscreenImage.url + ')' }">
      <div :ref="setRef" data-anim="0" class="absolute opacity-0 bottom-4 md:bottom-8 left-4 md:left-8 text-xl text-coolGray-50 md:font-light w-full md:w-2/5">
        <div>{{home.home.fullscreenImageText}}</div>
        <div class="text-xs mt-1">{{home.home.fullscreenImageCredits}}</div>
        </div>
    </div>
    <!-- FULLSCREEN IMAGE -->

    <!-- TEAM HEADING-->
    <div :ref="setRef" data-anim="0" class="h-auto opacity-0 overflow-hidden w-full flex flex-col md:flex-row justify-start md:justify-between items-start md:items-end mb-5 px-4 mt-16 md:mt-28 md:px-8">
      <div  class="font-light text-coolGray-900 text-5xl">{{text.team}}</div>
      <router-link :to="{ name: 'Team' }" class="font-light cursor-pointer">{{text.showAll}}</router-link>
    </div>
    <!-- TEAM HEADING-->

    <!-- TEAM MEMBERS-->
    <div v-if="constructedTeamMembers" class="w-full grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 px-4 md:px-8 mt-5 md:mt-2 mb-16">
      <div v-for="member in constructedTeamMembers" :key="member.slug">
        <div v-if="member?.images?.length > 0" :ref="setRef" data-anim="0"
          class="relative opacity-0 bg-coolGray-900 w-full cursor-pointer h-66 group"
          @click="navigateTo(member.slug)">
          <div class="transition duration-500 delay-300 absolute z-30 bottom-5 left-5 md:opacity-0 group-hover:opacity-100">
            <div class="font-bold text-lg text-coolGray-50">{{member.name.toUpperCase()}}</div>
          </div>
          <div class="transition duration-1000 absolute w-full h-full z-10 bg-coolGray-900 opacity-40 group-hover:opacity-0"></div>
          <div v-if="member?.tags?.length > 0" class="absolute top-0  mt-4 md:mt-5 pl-5 md:pl-5 w-full flex flex-row justify-start items-center  z-50">
            <div v-for="tag in member.tags" :key="tag">
              <div class="bg-coolGray-50 text-coolGray-900 mr-1 rounded-lg text-xs font-normal px-2 py-1 tracking-widest">{{tag.toUpperCase()}}</div>
            </div>
          </div>
          <div class="absolute w-full h-full overflow-hidden">
            <div class="transition duration-1000 bg-blend-luminosity bg-coolGray-700 ease-in-out transform-gpu group-hover:scale-105 w-full h-full bg-cover bg-no-repeat bg-center" :style="{ backgroundImage: 'url(' + member.images[0].url + ')' }" ></div>
          </div>

        </div>
      </div>
    </div>
    <!-- TEAM MEMBERS-->

    <!-- COMPONENTS-->
    <Footer />
    <getAllNewsQuery />
    <getHomeImagesQuery />
    <getHomeQuery />
    <getTeamMemberQuery />
    <getTeamMemberImagesQuery />
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

import Footer from '../components/footer.vue';
import getHomeImagesQuery from '../graphql/graphcms/getHomeImagesQuery.vue';
import getHomeQuery from '../graphql/graphcms/getHomeQuery.vue';
import getTeamMemberQuery from '../graphql/graphcms/getTeamMemberQuery.vue';
import getTeamMemberImagesQuery from '../graphql/graphcms/getTeamMemberImagesQuery.vue';
import getAllNewsQuery from '../graphql/graphcms/getAllNewsQuery.vue';

import intersectionObserver from '../mixins/intersectionObserver.vue';
import showHide from '../mixins/showHide.vue';

export default {
  name: 'Home',
  components: {
    Footer, getHomeImagesQuery, getHomeQuery, getTeamMemberQuery, getTeamMemberImagesQuery, getAllNewsQuery,
  },
  mixins: [intersectionObserver, showHide],

  data() {
    return {
      el: null,
      to: 0,
      swap: false,
      top: 0,
      stopAnimating: false,
      newsToggle: '',
    };
  },

  computed: {
    homeImages() {
      return this.$store.state.data.homeImages;
    },
    home() {
      return this.$store.state.data.home;
    },
    allNews() {
      return this.$store.state.data.allNews;
    },
    teamMembers() {
      return this.$store.state.data.teamMembers;
    },
    teamMemberImages() {
      return this.$store.state.data.teamMemberImages;
    },
    text() {
      return this.$store.state.lang.home[this.$store.state.lang.currentLang];
    },
    constructedTeamMembers() {
      const constructed = [];
      const that = this;
      if (this.teamMemberImages && this.teamMembers) {
        this.teamMembers.teams.forEach((member, i) => {
          constructed.push({
            images: that.teamMemberImages.teams[i].images,
            tags: member.tags,
            slug: member.slug,
            name: member.name,
          });
        });
        return constructed;
      }
      return null;
    },
    navBarBackground() {
      return this.$store.state.data.navBarBackground;
    },
    randomUrl() {
      if (this.homeImages?.home?.backgroundImages) {
        const random = Math.floor(Math.random() * (2 - 0 + 1) + 0);
        return this.homeImages.home.backgroundImages[random].url;
      }
      return null;
    },
  },

  watch: {
    swap() {
      if (!this.swap) {
        this.randomValues();
      }
    },
  },

  mounted() {
    document.title = 'Die nachhaltige Klettersportmarke re:white-climbing';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Nachhaltiges high-performance Chalk, buntes Tape und organic shirts und sweater aus Bio-Baumwolle.');

    window.scrollTo({ top: 0 });

    this.$store.commit('setNavBarBackground', false);
    this.$store.commit('setPosition', 'other');

    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = document.querySelector('#intro').getBoundingClientRect();
    this.top = (elemRect.bottom - bodyRect.bottom) * -1;

    this.to = document.querySelector('#first').getBoundingClientRect().top;
    this.el = document.querySelector('#re');
    this.randomValues();

    document.addEventListener('scroll', this.handeScroll);
  },

  beforeUnmount() {
    this.stopAnimating = true;
    document.removeEventListener('scroll', this.handeScroll);
    this.$store.commit('setNavBarBackground', true);
  },

  methods: {

    toggleNews(index) {
      if (this.newsToggle === index) {
        this.newsToggle = '';
      } else {
        this.newsToggle = index;
      }
    },

    navigateTo(slug) {
      this.$router.push({ name: 'TeamMember', params: { slug } });
    },

    swapBg() {
      this.swap = true;
    },

    handeScroll() {
      const that = this;
      if (window.scrollY >= window.innerHeight / 2) {
        if (!that.navBarBackground) {
          that.$store.commit('setNavBarBackground', true);
        }
      } else if (that.navBarBackground) {
        that.$store.commit('setNavBarBackground', false);
      }
    },

    scroll() {
      window.scrollTo({ top: this.to, behavior: 'smooth' });
    },

    randomValues() {
      if (!this.swap) {
        if (!this.stopAnimating) {
          const el = document.querySelector('#re');
          anime({
            targets: el,
            backgroundPositionX() {
              return `${anime.random(0, 5)}px`;
            },
            backgroundPositionY() {
              return `${anime.random(0, 5)}px`;
            },
            easing: 'easeInOutQuad',
            duration: 750,
            delay: 250,
            complete: this.randomValues,
          });
        }
      }
    },

  },

};
</script>
