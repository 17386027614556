<template>
<div></div>
</template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'getHomeQuery',

  watch: {
    home() {
      this.$store.commit('graphqlData', { item: 'home', result: this.home });
    },
  },

  setup() {
    const store = useStore();

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
      cachePolicy: 'network-only',
    });

    const getHomeQuery = gql`
      query MyQuery($lang:Locale!) {
        home(where: {id: "ckupah4uor1u70b97gadvcngp"}, locales: [$lang]) {
          fullscreenImageText
          fullscreenImageCredits
          introSubtext
          introText
          categories(first: 10) {
            name
            id
            slug
            info
          }
        }
      }
    `;

    const { data: home } = useQuery({
      query: getHomeQuery,
      variables,
    });

    return { home };
  },
};
</script>
